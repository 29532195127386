import { ProductCatalogueTableRow, ProductCatalogueFilterState } from "@/types";

export type TypeConfig = {
  text: string;
  value: keyof (ProductCatalogueTableRow | ProductCatalogueFilterState);
  sortable?: boolean;
  searchable?: boolean;
  style?: string;
  filter?: boolean;
};

const config = [
  {
    text: "Product name",
    value: "product",
    sortable: "asc",
    searchable: true,
    style: "pl-12 w-[22%]",
  },
  {
    text: "Tier",
    value: "tier",
    filter: true,
    style: "w-[7%]",
  },
  {
    text: "Currency",
    value: "currency",
    filter: true,
    style: "w-[6%]",
  },
  {
    text: "Countries",
    value: "countryLabel",
    filter: true,
    style: "w-[15%]",
  },
  {
    text: "Product state",
    value: "state",
    filter: true,
    style: "w-[20%]",
  },
  {
    text: "Status",
    value: "decision",
    filter: true,
    style: "w-[15%]",
  },
  {
    text: "Decision",
    value: "decisionType",
    style: "w-[15%] pr-4",
    filter: true,
  },
] as TypeConfig[];

export default config;

export const tooltipStatus = [
  {
    type: "Approved/Declined: ",
    description: "Can be Manual or Automatic",
  },
  {
    type: "Info required: ",
    description:
      "The Brand approver has asked for more information via the Brand Approver Portal",
  },
  {
    type: "Pending approval: ",
    description:
      "The customer has filled in the Tier 3 form but the Brand Approver has not reached a decision yet",
  },
  {
    type: "Internal approval: ",
    description:
      "Tier 6 and Tier 7 products which are approved by the Brand Approver but require an extra step to be done. Once the task is completed by CS agent, this can be manually Approved or Declined",
  },
  {
    type: "Restricted: ",
    description:
      "Tier 4 and Tier 5 products are automatically restricted and do not show to the customer. They can only be Approved manually.",
  },
];
