<!-- JSONForms custom renderer for single checkbox control -->
<script setup lang="ts">
import { rendererProps, useJsonFormsOneOfControl } from "@jsonforms/vue";
import { ControlElement } from "@jsonforms/core";
import { TwoRadioField } from "@wegift/two-components";
import { ComputedRef } from "vue";

const props = defineProps({
  ...rendererProps<ControlElement>(),
});

// useJsonFormsOneOfControl's "control" property is actually a ComputedRef hence overriding it
const { control, handleChange } = useJsonFormsOneOfControl(
  props
) as unknown as Omit<ReturnType<typeof useJsonFormsOneOfControl>, "control"> & {
  control: ComputedRef<ReturnType<typeof useJsonFormsOneOfControl>["control"]>;
};

const onChange = (value: boolean | string | number) => {
  handleChange(control.value.path, value);
};
</script>
<template>
  <TwoRadioField
    v-for="op in control.schema.oneOf"
    class="mb-2"
    :model-value="typeof control.data == 'undefined' ? '' : control.data"
    :name="control.path"
    @change="onChange"
    :value="op.const"
    >{{ op.title }}</TwoRadioField
  >
</template>
