<script setup lang="ts">
import {
  TwoRadioField,
  TwoButton,
  TwoTextField,
  markAllFormInputAsTouched,
} from "@wegift/two-components";
import {
  DecisionStatusEnum,
  DefaultService as BrandApprovalService,
} from "@/api/brand-approval-service";
import { ref } from "vue";

const props = defineProps<{
  customerId: string;
  productCode: string;
  decision: "approved" | "denied";
}>();

const emit = defineEmits<{ (e: "override"): void; (e: "cancel"): void }>();

const decisionsMapping = {
  approved: DecisionStatusEnum.APPROVED,
  denied: DecisionStatusEnum.DENIED,
};
const status = ref<DecisionStatusEnum>(decisionsMapping[props.decision]);
const reason = ref();
const formRef = ref<HTMLFormElement>();
const overriding = ref(false);

const manuallyOverrideStatus = async () => {
  if (!reason.value && formRef.value) {
    markAllFormInputAsTouched(formRef.value);
    return;
  }
  if (
    confirm(
      `Are you sure you want to override status for ${props.productCode}?`
    )
  ) {
    try {
      const decisionChanged = decisionsMapping[props.decision] !== status.value;
      if (decisionChanged) {
        await BrandApprovalService.postApiV1DecisionBatch({
          customer_id: props.customerId,
          decision: status.value,
          product_codes: [props.productCode],
          reason: reason.value,
        });
      }
      alert("Status override successful.");
    } catch (ex) {
      alert("An error occurred while trying to override.");
      throw ex;
    }
    emit("override");
  }
};
</script>

<template>
  <div>
    <h2>Manual override</h2>
    <p>
      Manually override the status of brand: <strong>{{ productCode }}</strong>
    </p>
    <form @submit.prevent="manuallyOverrideStatus" ref="formRef">
      <h3 class="mt-8">Status</h3>
      <TwoRadioField
        v-model="status"
        name="approval-status"
        :value="DecisionStatusEnum.APPROVED"
        >Approve</TwoRadioField
      >
      <TwoRadioField
        v-model="status"
        name="approval-status"
        :value="DecisionStatusEnum.DENIED"
        >Decline</TwoRadioField
      >
      <h3 class="mt-8">Reason</h3>
      <p>This won't be visible to the customer.</p>
      <TwoTextField
        multiline
        :max-length="150"
        char-count
        v-model="reason"
        placeholder="Specify why you want to change the status"
        :required="true"
      ></TwoTextField>
      <footer class="mt-10 flex">
        <TwoButton
          type="button"
          wide
          class="btn-black"
          @click="manuallyOverrideStatus"
          :loading="overriding"
        >
          Manually override status
        </TwoButton>
      </footer>
    </form>
  </div>
</template>
