import { FormSchema } from "@/types";

export async function getBrandApprovalFormSchema(): Promise<FormSchema> {
  const fetchSchema = fetch(
    import.meta.env.VITE_BRAND_APPROVAL_FORM_SCHEMA_URL,
    { cache: "no-store" }
  );
  const fetchUISchema = fetch(
    import.meta.env.VITE_BRAND_APPROVAL_FORM_UISCHEMA_URL,
    { cache: "no-store" }
  );
  const result = await Promise.all([fetchSchema, fetchUISchema]);
  return {
    schema: await result[0].json(),
    uischema: await result[1].json(),
  };
}
