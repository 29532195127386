<script setup lang="ts">
import { defineProps } from "vue";
import { formatDate } from "@/utils";
import { formatDecisionDisplay } from "@/utils/formatDecisionDisplay";
import { TwoPopover } from "@wegift/two-components";
import { Decision } from "@/api/brand-approval-service";

const emit = defineEmits(["getDecisionHistory"]);
const baseURL = import.meta.env.VITE_SCION_BASE_URL;

const { decisionDate, decisionType } = defineProps<{
  decisionDate: string;
  decisionType: string;
  note?: string | undefined;
  historyDecisions?: Decision[] | undefined;
}>();

const handleClick = () => {
  emit("getDecisionHistory");
};
</script>

<template>
  <div class="flex flex-col">
    <p class="font-bold capitalize text-grey-800">
      {{ decisionType }}
    </p>
    <div v-if="!!note">
      <TwoPopover trigger="click" placement="bottom">
        <template #reference>
          <p
            class="mb-4 flex cursor-pointer text-sm font-bold text-hyperlink underline underline-offset-4"
            @click="handleClick"
          >
            View notes
          </p>
        </template>
        <template v-slot="{ close }">
          <div
            class="shadow-lgl min-w-[800px] max-w-md rounded border border-grey-200 px-4 py-6"
          >
            <div
              v-for="decision in historyDecisions?.sort((a, b) =>
                b.decision_time.localeCompare(a.decision_time)
              )"
              class="mb-2"
            >
              <span class="font-bold">
                {{ formatDate(decision.decision_time) }} -
                {{ formatDecisionDisplay(decision.decision) }}
                <span v-if="decision.user_id">
                  by
                  <a
                    v-bind:href="`${baseURL}/admin/users/${decision.user_id}`"
                    target="_blank"
                    class="text-hyperlink underline underline-offset-4"
                    >{{ decision.user_id }}</a
                  >
                </span>
              </span>
              <span class="italic" v-if="decision.override_reason">{{
                ` "${decision.override_reason}"`
              }}</span>
            </div>
          </div>
        </template>
      </TwoPopover>
    </div>
    <p v-else>
      {{ formatDate(decisionDate) }}
    </p>
  </div>
</template>
