import {
  STATUS_REQUIRE_APPROVAL,
  STATUS_INFORMATION_REQUESTED,
  STATUS_REQUIRES_CUSTOMER_ACTION,
  STATUS_DENIED,
  STATUS_PROCESSING,
  STATUS_PENDING,
} from "@/constants";

export function formatDecisionDisplay(status: string): string {
  if (status === STATUS_REQUIRE_APPROVAL) {
    return "Require approval";
  }
  if (status === STATUS_INFORMATION_REQUESTED) {
    return "Info required";
  }
  if (status === STATUS_REQUIRES_CUSTOMER_ACTION) {
    return "Requires customer action";
  }
  if (status === STATUS_DENIED) {
    return "Declined";
  }
  if (status === STATUS_PROCESSING) {
    return "Updating status";
  }
  if (status === STATUS_PENDING) {
    return "Pending approval";
  }
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
}
