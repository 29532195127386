<script lang="ts" setup>
import KeyValues from "@/components/KeyValues.vue";

const env = import.meta.env;
</script>

<template>
  <main class="overflow-hidden">
    <h2>Environment</h2>
    <KeyValues :obj="env" />
  </main>
</template>
