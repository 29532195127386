<script lang="ts" setup>
import { getBrandApprovalFormSchema } from "@/form-schema";
import { FormSchema } from "@/types";
import { JsonForms } from "@jsonforms/vue";
import { vanillaRenderers } from "@jsonforms/vue-vanilla";
import { ref } from "vue";
import formAjv from "@/form-ajv";
import useForm from "@/composables/useForm";
import {
  ApiError as BrandApprovalServiceApiError,
  ApplicationTypeEnum,
  BrandApprovalApplicationResponse,
  BrandApprovalApplicationService,
} from "@/api/brand-approval-application-service";
import TwoButton from "@wegift/two-components/src/components/TwoButton.vue";
import _ from "lodash";
import CheckboxRenderer from "@/renderers/CheckboxRenderer.vue";
import CheckboxGroupRenderer from "@/renderers/CheckboxGroupRenderer.vue";
import RadioRenderer from "@/renderers/RadioRenderer.vue";
import {
  checkboxGroupTester,
  checkboxTester,
  radioTester,
} from "@/renderers/testers";
import router from "@/router";
const props = defineProps<{
  customerId: string;
  customerName?: string;
}>();

const emit = defineEmits<{
  (e: "submit", data: any): void;
  (e: "openModal"): void;
}>();

const renderers = Object.freeze([
  ...vanillaRenderers,
  { tester: checkboxTester, renderer: CheckboxRenderer },
  { tester: checkboxGroupTester, renderer: CheckboxGroupRenderer },
  { tester: radioTester, renderer: RadioRenderer },
]);
const { data, validationMode, change, validate, showSubmitError, setData } =
  useForm();

let formSchema: FormSchema;
const formLoadError = ref(false);
const disabledForm = ref(false);
const submittingForm = ref(false);
let existingData: BrandApprovalApplicationResponse;

const getExistingFormData = async () => {
  try {
    const existingData =
      await BrandApprovalApplicationService.getBrandApprovalApplication(
        props.customerId
      );
    for (const key in existingData.answers) {
      // backend returns empty values as null but the JSONFOMRS validations works correctly
      // if the empty values are undefined hence deleting all null value properties
      if (existingData.answers[key] === null) delete existingData.answers[key];
    }
    return existingData;
  } catch (ex) {
    if (ex instanceof BrandApprovalServiceApiError && ex.status === 404) {
      return null;
    }
    throw ex;
  }
};

try {
  // make call for getting schema and existing answers in parallel
  const result = await Promise.all([
    getBrandApprovalFormSchema(),
    getExistingFormData(),
  ]);
  formSchema = result[0];
  disabledForm.value = formSchema.schema.disabledForm === true;
  if (disabledForm.value) {
    alert(
      "Due to maintenance, it is not possible to update this form at this time. Sorry for any inconvenience. Please try again in a few hours"
    );
  }
  if (result[1]) {
    existingData = result[1];
    setData(existingData.answers);
  }
} catch (ex) {
  formLoadError.value = true;
  // Cannot throw here if we want to show error message as this is wrapped in Suspense.
  console.log(ex);
}

/**
 * Remove all hidden fields.
 * Fields are hidden/shown based on the rules provided by jsonforms: https://jsonforms.io/docs/uischema/rules.
 * However it does not provide a way to empty them so this function does it by re-checking the conditions that are in uicshema rule
 * and remove fields if hidden.
 */
const removeHiddenFields = () => {
  // remove otherUseCase field if hidden
  const { useCase, otherUseCase } = data.value;
  if (!useCase?.includes("OTHER") && otherUseCase) {
    delete data.value.otherUseCase;
  }
};

const handleModal = () => {
  if (validate()) {
    if (router.currentRoute.value.fullPath === "/approval-form") {
      onSubmit();
    } else {
      emit("openModal");
    }
  }
};
const onSubmit = async () => {
  try {
    submittingForm.value = true;
    if (!_.isEqual(existingData?.answers, data.value)) {
      removeHiddenFields();
      await BrandApprovalApplicationService.storeBrandApprovalApplication({
        applicationType: ApplicationTypeEnum.AUTOMATED,
        customerId: props.customerId,
        answers: data.value,
      });
    }
  } catch (error) {
    alert("An error occurred while submitting the form.");
    submittingForm.value = false;
    throw error;
  }
  // emit submit event now as form successfully submitted
  alert("Form submitted successfully.");
  emit("submit", data.value);
  submittingForm.value = false;
};

const onSubmitRef = ref(onSubmit);
defineExpose({
  onSubmit: onSubmitRef,
});
</script>

<template>
  <div class="json-form">
    <h1 v-if="customerName">{{ customerName }} brand screening</h1>
    <JsonForms
      class="mt-10"
      v-if="!formLoadError"
      :data="data"
      :renderers="renderers"
      :schema="formSchema?.schema"
      :uischema="formSchema?.uischema"
      @change="change"
      :ajv="formAjv"
      :validation-mode="validationMode"
      :config="{ hideRequiredAsterisk: true }"
    />
    <p v-if="formLoadError" class="text-red-500">
      An error occurred while loading the form.
    </p>
    <p v-if="showSubmitError" class="mb-2 text-lg text-red-500">
      Please supply an answer for each of the questions above.
    </p>
    <TwoButton
      data-testid="submit-t2-form"
      wide
      class="btn-black"
      @click="handleModal"
      name="submit"
      :disabled="disabledForm"
      :loading="submittingForm"
    >
      Submit
    </TwoButton>
  </div>
</template>
