function naturalSorter<T extends string | null | undefined>(arr: T[]): T[] {
  return arr.sort(function (a: T, b: T) {
    // Type assertion is needed since 'a' and 'b' can still be null or undefined, but localeCompare requires strings
    if (a === null || a === undefined) {
      return b === null || b === undefined ? 0 : -1;
    } else if (b === null || b === undefined) {
      return 1;
    } else {
      return (a as string).localeCompare(b as string, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    }
  });
}

export default naturalSorter;
