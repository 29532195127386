<script setup lang="ts">
import { TwoTextField, TwoButton } from "@wegift/two-components";
import Modal from "./Modal/index.vue";
import { onMounted, ref } from "vue";
import _ from "lodash";
import {
  DecisionStatusEnum,
  DefaultService as BrandApprovalService,
} from "@/api/brand-approval-service";
import { ProductCatalogueTableRow, ValidDecisions } from "@/types";
import {
  BrandApprovalApplicationRequest,
  BrandApprovalApplicationService,
} from "@/api/brand-approval-application-service";

const emit = defineEmits(["handleShowOverrideModal", "refreshDataTable"]);

const { customerId, selectedProducts, decision, isRetrictedProducts } =
  defineProps<{
    customerId: string | null;
    isRetrictedProducts: boolean;
    decision: ValidDecisions | null;
    selectedProducts: ProductCatalogueTableRow[];
  }>();

const approveSingleProduct = selectedProducts.length === 1;

let reason = ref<string>("");

function handleCancelConfirm() {
  emit("handleShowOverrideModal");
}

async function handleDecisionOverride() {
  if (customerId && decision && reason.value)
    if (decision === DecisionStatusEnum.REQUIRES_CUSTOMER_ACTION) {
      const BAASRequestBody: BrandApprovalApplicationRequest = {
        customerId: customerId,
        //@ts-ignore
        applicationType: "RESET",
        answers: {},
        productCodes: selectedProducts.map((product) => product.code),
      };
      try {
        await BrandApprovalApplicationService.storeBrandApprovalApplication(
          BAASRequestBody
        );
        emit("refreshDataTable");
      } catch (ex) {
        alert("An error occurred while trying to reset decision.");
        throw ex;
      }
    } else {
      try {
        await BrandApprovalService.postApiV1DecisionBatch({
          customer_id: customerId,
          decision: decision,
          product_codes: selectedProducts.map((product) => product.code),
          reason: reason.value,
        });
        emit("refreshDataTable");
      } catch (ex) {
        alert("An error occurred while trying to override.");
        throw ex;
      }
    }
}

const action =
  decision === DecisionStatusEnum.APPROVED
    ? "approve"
    : decision === DecisionStatusEnum.DENIED
    ? "decline"
    : "reset";

const title = approveSingleProduct
  ? `Manually ${action} ${selectedProducts[0].code}`
  : `Manually ${action} the selected products?`;

const displayProductCodes = selectedProducts
  .map((product) => product.code)
  .join(", ");
</script>

<template>
  <Modal
    :title="title"
    size="large"
    :closable="true"
    @onCloseClick="handleCancelConfirm"
    classNameContent="mt-20"
  >
    <template #body>
      <div class="mb-6">
        <p class="text-sm">
          Manually {{ action }} the status of brand:
          <span class="font-semibold text-neutral-700 opacity-75">{{
            displayProductCodes
          }}</span>
        </p>
        <p class="text-red-700" v-if="isRetrictedProducts">
          Note: {{ displayProductCodes }} is a Restricted Product
        </p>
      </div>
      <TwoTextField
        multiline
        v-model="reason"
        label="Reason (This won’t be visible to the customer)"
        :maxLength="150"
        placeholder="Specify why you want to change the status"
        :charCount="true"
      />
    </template>
    <template #button>
      <div class="flex w-full justify-end">
        <TwoButton @click="handleCancelConfirm">Cancel</TwoButton>
        <TwoButton
          type="submit"
          :disabled="reason.length === 0"
          class="btn-primary capitalize"
          @click="handleDecisionOverride"
          >{{ action }}</TwoButton
        >
      </div>
    </template>
  </Modal>
</template>
