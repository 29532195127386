<!-- JSONForms custom renderer for single checkbox control -->
<script setup lang="ts">
import { rendererProps, useJsonFormsControl } from "@jsonforms/vue";
import { ControlElement } from "@jsonforms/core";
import { TwoCheckbox } from "@wegift/two-components";
import { ComputedRef } from "vue";

const props = defineProps({
  ...rendererProps<ControlElement>(),
});

// useJsonFormsControl's "control" property is actually a ComputedRef hence overriding it
const { control, handleChange } = useJsonFormsControl(props) as unknown as Omit<
  ReturnType<typeof useJsonFormsControl>,
  "control"
> & {
  control: ComputedRef<ReturnType<typeof useJsonFormsControl>["control"]>;
};

const onChange = (value: boolean) => {
  handleChange(control.value.path, value);
};
</script>
<template>
  <TwoCheckbox
    class="mb-2"
    :model-value="control.data || false"
    @change="onChange"
  >
    <span class="text-lg font-bold text-black">
      {{ control.uischema.label }}
    </span>
  </TwoCheckbox>
</template>
