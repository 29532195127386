/**
 * JSONForms testers for custom renderers
 */

import { optionIs, rankWith, schemaTypeIs } from "@jsonforms/core";

const HIGHEST_TESTER_RANK = 5;

export const checkboxGroupTester = rankWith(
  HIGHEST_TESTER_RANK,
  schemaTypeIs("array")
);

export const checkboxTester = rankWith(
  // keep it less than highest as radio field can be of boolean type as well
  // i.e. radio option is provided in uischema and type is boolean so it needs higher
  HIGHEST_TESTER_RANK - 1,
  schemaTypeIs("boolean")
);

export const radioTester = rankWith(
  HIGHEST_TESTER_RANK,
  optionIs("format", "radio")
);
