/**
 * File containing modifications to the Ajv instance that is passed to json forms
 */

import Ajv, { KeywordDefinition, Schema } from "ajv";

const ajv = new Ajv({
  allErrors: true, // set to true for seeing all field errors
  strict: false, // set to false for removing predefined keywords
  useDefaults: true, // to use default values provided in schema
});

// Remove const and oneOf pre defined keywords which are triggered in case empty enum value selected.
// This is because on forms, we are handling showing of error message for empty enum selection.
// via custom canBeEmpty validator.
ajv.removeKeyword("const");
ajv.removeKeyword("oneOf");

// Adding custom keywords to ajv
ajv.addKeyword({
  // Creating a new canBeEmpty keyword for empty string validation
  // because ajv built in required only triggers when value is undefined
  keyword: "canBeEmpty",
  type: ["string", "array"],
  schemaType: "boolean",

  error: { message: "value is required" },
  validate: (schema: Schema, data: any) => {
    if (schema === false) {
      return (
        // for empty strings
        (typeof data === "string" && data.trim() !== "") ||
        // for empty arrays
        (Array.isArray(data) && data.length !== 0)
      );
    }
    return true;
  },
});
ajv.addKeyword({
  keyword: "isEmail",
  type: "string",
  schemaType: "boolean",
  error: { message: "should be an email" },
  validate: (schema: Schema, data: any) => {
    if (schema === true) {
      return /^\S+@\S+\.[A-Za-z]{2,}$/.test(data);
    }
    return true;
  },
});
ajv.addKeyword({
  keyword: "isUrl",
  type: "string",
  schemaType: "boolean",
  error: { message: "should be a valid URL" },
  validate: (schema: Schema, data: any) => {
    if (schema === true) {
      return /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        data
      );
    }
    return true;
  },
});

// keyword for checking if an element is part of an array
ajv.addKeyword({
  keyword: "includes",
  type: "array",
  schemaType: "string",
  error: { message: "selection is invalid" },
  validate: (schema: Schema, data: any) => {
    return data && data.includes(schema);
  },
});

/**
 * NOTE: ajv-errors do not work with jsonforms hence this function exists for updating error messages of keywords.
 * Updates a keyword error message. AJV does not provide default way for updating error messages.
 * This function saves a keyword definition temporarily in a var, removes original keyword and adds saved keyword
 * with modified error message
 * @param keyword keyword to update
 * @param message new message
 */
export function updateKeywordErrorMessage(keyword: string, message: string) {
  const keywordDef = ajv.getKeyword(keyword) as KeywordDefinition;
  ajv.removeKeyword(keyword);
  if (keywordDef.error) {
    keywordDef.error = {
      ...keywordDef.error,
      message,
    };
  }
  ajv.addKeyword(keywordDef);
}

// update error messages for predefined keywords
updateKeywordErrorMessage("required", "value is required");

export default ajv;
