<script lang="ts" setup>
import { onSetupEvent, sendExitEvent } from "@/parent-interface";
import { OpenAPI as ScionAPI } from "@/api/scion";
import { OpenAPI as BrandApprovalAPI } from "@/api/brand-approval-service";
import productCatalogueStore from "@/sub-stores/product-catalogue";
import BackButton from "@/components/BackButton.vue";
import ProductCatalogue from "@/components/ProductCatalogue.vue";
import { useGlobalStore } from "../store";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "@/api/brand-approval-application-service";

const globalStore = useGlobalStore();

onSetupEvent(async (event) => {
  const { customerId, customerName, accessToken } = event.data;
  if (customerId && customerName && accessToken) {
    productCatalogueStore.setCustomer(customerId, customerName);
    globalStore.customerId = customerId;
    globalStore.customerName = customerName;
    ScionAPI.TOKEN = BrandApprovalAPI.TOKEN = event.data.accessToken;
    BrandApprovalApplicationServiceAPI.TOKEN = event.data.accessTokenBaas;
  }
});
</script>
<template>
  <main class="p-4">
    <BackButton class="mb-4" @click="sendExitEvent">Back</BackButton>
    <p class="mb-5 text-2xl font-extrabold">Customer Catalog</p>
    <ProductCatalogue v-if="productCatalogueStore.state.customerId" />
  </main>
</template>
