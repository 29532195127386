/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DecisionStatusEnum {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  DENIED = "DENIED",
  INFORMATION_REQUESTED = "INFORMATION_REQUESTED",
  REQUIRES_CUSTOMER_ACTION = "REQUIRES_CUSTOMER_ACTION",
  RESTRICTED = "RESTRICTED",
}
