<script setup lang="ts">
import { ref } from "vue";
import BrandApprovalForm from "@/components/BrandApprovalForm.vue";
import { onSetupEvent, sendExitEvent } from "../parent-interface";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "@/api/brand-approval-application-service";
import BackButton from "@/components/BackButton.vue";

const customerId = ref<string>();
const customerName = ref<string>();

onSetupEvent((event) => {
  customerId.value = event.data.customerId;
  customerName.value = event.data.customerName;
  BrandApprovalApplicationServiceAPI.TOKEN = event.data.accessToken;
});

const onSubmit = () => {
  console.debug("Submit called");
  sendExitEvent();
};
</script>

<template>
  <main>
    <BackButton class="mb-4" @click="sendExitEvent">Back</BackButton>
    <Suspense v-if="customerId">
      <template #default>
        <BrandApprovalForm
          :customer-id="customerId"
          :customer-name="customerName"
          @submit="onSubmit"
        />
      </template>
      <template #fallback> Loading... </template>
    </Suspense>
  </main>
</template>
