<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imageUrl: { type: String },
    code: { type: String },
    name: { type: String },
  },
});
</script>

<template>
  <div class="flex items-center hover:underline hover:underline-offset-4">
    <img
      v-if="!!imageUrl"
      :src="imageUrl"
      class="mr-3 h-12 rounded bg-cover shadow-lg"
    />
    <div>
      <p class="font-bold">{{ code }}</p>
      <p class="text-sm">{{ name }}</p>
    </div>
  </div>
</template>
