<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  TwoCheckbox,
  TwoSelectField,
  TwoSearchField,
  TwoPopover,
} from "@wegift/two-components";
import {
  ProductCatalogueFilterState,
  ProductCatalogueTableRow,
  ValidDecisions,
} from "@/types";
import ProductFormatter from "@/components/ProductFormatter.vue";
import StatusFormatter from "@/components/StatusFormatter.vue";
import DecisionFormatter from "@/components/DecisionFormatter.vue";
import { OpenAPI as BrandApprovalApplicationServiceAPI } from "@/api/brand-approval-application-service";

import LoadingSpinner from "@wegift/two-components/src/components/LoadingSpinner.vue";
import {
  ProductDetailsForBackOffice,
  ProductDetailsService,
} from "@/api/scion";
import {
  Decision,
  DetailedDecision,
  DecisionStatusEnum,
  DefaultService as BrandApprovalService,
  ApiError as BrandApprovalAPIError,
} from "@/api/brand-approval-service";
import config, { tooltipStatus } from "./TableConfig";

import { assert } from "@/utils/typescript";
import _ from "lodash";
import { SelectOption } from "@wegift/two-components";
import { computed } from "@vue/reactivity";
import productCatalogueStore from "@/sub-stores/product-catalogue";
import Table from "@/components/Table.vue";
import { formatDecisionDisplay } from "@/utils/formatDecisionDisplay";
import OverrideDecisionModal from "@/components/OverrideDecisionModal.vue";
import { STATUS_PROCESSING } from "@/constants";
import naturalSorter from "@/utils/naturalSorter";
import "../styles/product-catalog.sass";
import { onSetupEvent } from "@/parent-interface";
// import { onSetupEvent } from "@/parent-interface";

const defaultFilterValue = {
  searchTerm: "",
  currency: "all",
  decision: "all",
  state: "all",
  countryLabel: "all",
  decisionType: "all",
  tier: "all",
};

let currentPage = ref(1);
let itemsPerPage = ref(20);
const ELLIPSIS = "...";

const shouldAddPageNumber = (pageNumber: number, totalPages: number) => {
  return (
    pageNumber === 1 ||
    pageNumber === totalPages ||
    (pageNumber >= currentPage.value - 2 && pageNumber <= currentPage.value + 2)
  );
};

const shouldAddEllipsis = (pageNumber: number, totalPages: number) => {
  return pageNumber === 2 || pageNumber === totalPages - 1;
};

const paginationArray = computed(() => {
  const array = [];
  const totalPages = lastPage.value;

  for (let i = 1; i <= totalPages; i++) {
    if (shouldAddPageNumber(i, totalPages)) {
      array.push(i);
    } else if (shouldAddEllipsis(i, totalPages)) {
      array.push(ELLIPSIS);
    }
  }

  return array;
});

const loadingData = ref(false);
let showFilter = ref(false);
let selectedProducts = ref<ProductCatalogueTableRow[]>([]);
let sorting = ref<"asc" | "desc">("asc");
let showOverrideModal = ref(false);
let decision = ref<ValidDecisions | null>(null);
let isAllSameStatus = ref<boolean>(false);
let currentFilterState = ref<ProductCatalogueFilterState>({
  ...defaultFilterValue,
});
let historyDecisions = ref<Decision[]>([]);
const customerId = computed(() => productCatalogueStore.state.customerId);
const currencyOptions = ref<SelectOption[]>([]);
const countryOptions = ref<SelectOption[]>([]);
const stateOptions = ref<SelectOption[]>([]);
const decisionOptions = ref<SelectOption[]>([]);
const decisionTypeOptions = ref<SelectOption[]>([]);
const tierOptions = ref<SelectOption[]>([]);
const tableDataLength = computed(() => {
  return tableData.value?.length || 0;
});
const lastPage = computed(() => {
  if (!tableData.value?.length) {
    return 1;
  }
  return Math.ceil(tableData.value.length / itemsPerPage.value);
});
const currentPageData = computed(() => {
  const start = Number((currentPage.value - 1) * itemsPerPage.value);
  const end = start + Number(itemsPerPage.value);
  return tableData?.value?.slice(start, end);
});

onMounted(async () => {
  if (customerId.value) await initializeCatalogue();
});

const initializeCatalogue = async () => {
  try {
    await generateTableData();
  } catch (ex) {
    alert("An error occurred while rendering product catalogue");
    throw ex;
  }
};

let productCatalogueData: Array<ProductCatalogueTableRow> = [];
let tableData = ref<Array<ProductCatalogueTableRow>>();

const fetchDecisions = async (
  customerIdValue: string
): Promise<Array<Decision>> => {
  let decisions: Array<Decision> = [];
  try {
    const decisionData = await BrandApprovalService.getApiV1Decision(
      customerIdValue
    );
    decisions = decisionData.decisions ?? [];
  } catch (ex) {
    loadingData.value = false;
    if (ex instanceof BrandApprovalAPIError && ex.status === 404) {
      alert(
        "Cannot find any decisions for products in the catalog. Please try again later."
      );
      return [];
    }
    throw ex;
  }
  return decisions;
};

const fetchProductDetails = async (productCodes: string[]): Promise<any[]> => {
  const batchSize = 1000;
  const productCodeBatches = _.chunk(productCodes, batchSize);

  const allRequests = productCodeBatches.map((batch) => {
    return ProductDetailsService.getProductDetailsForBackOffice({
      product_codes: batch,
    });
  });

  const allResults = await Promise.all(allRequests);

  let allProducts: any = [];
  allResults.forEach((result) => {
    allProducts = [...allProducts, ...result.products];
  });

  setCountryAndCurrencyOptions(allProducts);

  return allProducts;
};

const generateTableData = async () => {
  assert(customerId.value, "Customer id not found for fetching token");
  loadingData.value = true;
  const decisions = await fetchDecisions(customerId.value);

  const productCodes = _.uniq(
    decisions.map((decision: Decision) => decision.product_code)
  );
  const allProducts = await fetchProductDetails(productCodes);

  if (decisions) {
    productCatalogueData = decisions.map((data) => {
      let productMetadata: ProductDetailsForBackOffice | undefined =
        allProducts.find((product) => product.code === data.product_code);

      if (!productMetadata) {
        console.warn(
          `Product ${data.product_code} not found in Scion but was present in brand approval API.`
        );
        productMetadata = {
          code: data.product_code,
          countries: [],
          currency: "NA",
          image_url: "",
          name: "Not available",
          state: "",
        };
      }

      return {
        code: data.product_code,
        name: productMetadata.name,
        countryCodes: productMetadata.countries.map((country) => country.code),
        image_url: productMetadata.image_url,
        currency: productMetadata.currency,
        countryLabel: productMetadata.countries
          .map((country) => country.name)
          .join(", "),
        state: stateToLabel(productMetadata.state),
        decision: data.decision,
        decision_type:
          data.decision_type?.toLowerCase() ??
          Decision.decision_type.AUTOMATIC.toLowerCase(),
        decision_date: data.decision_time,
        user: data.user_id ?? undefined,
        override_reason: data.override_reason ?? undefined,
        tier: data.settings_group_name ?? "Tier 1",
      };
    });

    tableData.value = _.cloneDeep(
      _.orderBy(productCatalogueData, "code", "asc")
    );
    setDecisionOptions();
    setDecisionTypeOptions();
    setTierOptions();
    loadingData.value = false;
  }
};

const setDecisionTypeOptions = () => {
  const availableDecisionTypes = [
    ...new Set(productCatalogueData.map((product) => product.decision_type)),
  ];

  decisionTypeOptions.value = availableDecisionTypes.map((decision) => ({
    id: decision,
    label: decision,
    value: decision,
  }));
  decisionTypeOptions.value.unshift({
    id: "all",
    label: "All",
    value: "all",
  });
};

const setDecisionOptions = () => {
  const availableDecisions = [
    ...new Set(productCatalogueData.map((product) => product.decision)),
  ];

  decisionOptions.value = availableDecisions.map((decision) => ({
    id: decision,
    label: formatDecisionDisplay(decision),
    value: decision,
  }));
  decisionOptions.value.unshift({
    id: "all",
    label: "All",
    value: "all",
  });
};

const setTierOptions = () => {
  const availableTiers = [
    ...new Set(productCatalogueData.map((product) => product.tier)),
  ].filter((tier) => tier !== null);

  const sortedAvailableTiers = naturalSorter(availableTiers);

  tierOptions.value = sortedAvailableTiers.map((tier) => {
    const value = tier!;
    return {
      id: value,
      label: value,
      value: value,
    };
  });
  tierOptions.value.unshift({
    id: "all",
    label: "All",
    value: "all",
  });
};

function stateToLabel(state: string): string {
  return _.startCase(state.toLowerCase());
}

const setCountryAndCurrencyOptions = (
  products: ProductDetailsForBackOffice[]
) => {
  const countries = _.uniqBy(_.flatten(_.map(products, "countries")), "code");
  const currencies = _.uniq(_.map(products, "currency"));
  const states = _.uniq(_.map(products, "state"));

  countryOptions.value = [
    {
      id: "all",
      label: "All",
      value: "all",
    },
  ];
  currencyOptions.value = [
    {
      id: "all",
      label: "All",
      value: "all",
    },
  ];
  stateOptions.value = [
    {
      id: "all",
      label: "All",
      value: "all",
    },
  ];
  countries.forEach((country) => {
    countryOptions.value.push({
      id: country.code,
      label: country.name,
      value: country.code,
    });
  });
  currencies.forEach((currency) => {
    currencyOptions.value.push({
      id: currency,
      label: currency,
      value: currency,
    });
  });
  states.forEach((state) => {
    let label = stateToLabel(state);
    stateOptions.value.push({
      id: label,
      label: label,
      value: label,
    });
  });
};

const filterProductCatalogue = function () {
  currentPage.value = 1;
  tableData.value = _.orderBy(
    productCatalogueData,
    [(item) => item.code.toLowerCase()],
    [sorting.value]
  )
    .map((product) => {
      if (selectedProducts.value.find((row) => row.code === product.code)) {
        product.selected = true;
      }
      return product;
    })
    .filter((product) => {
      return (
        product.name
          .toLowerCase()
          .includes(currentFilterState.value.searchTerm.toLowerCase()) ||
        product.code
          .toLowerCase()
          .includes(currentFilterState.value.searchTerm.toLowerCase())
      );
    })
    .filter((product) => {
      return ["all", product.currency].includes(
        currentFilterState.value.currency
      );
    })
    .filter((product) => {
      return ["all", product.state].includes(currentFilterState.value.state);
    })
    .filter((product) => {
      return ["all", product.decision].includes(
        currentFilterState.value.decision
      );
    })
    .filter((product) => {
      return ["all", product.decision_type].includes(
        currentFilterState.value.decisionType
      );
    })
    .filter((product) => {
      return ["all", ...product.countryCodes].includes(
        currentFilterState.value.countryLabel
      );
    })
    .filter((product) => {
      return ["all", product.tier].includes(currentFilterState.value.tier);
    });
};

function handleSelectedProducts(row: ProductCatalogueTableRow) {
  row.selected = !row.selected;
  const index = selectedProducts.value.findIndex((v) => v.code === row.code);
  if (index === -1) {
    selectedProducts.value.push(row);
  } else {
    selectedProducts.value.splice(index, 1);
  }
  if (selectedProducts.value.length === 0) {
    isAllSameStatus.value = false;
  } else {
    isAllSameStatus.value = selectedProducts.value.every(
      (product) => product?.decision === selectedProducts.value[0]?.decision
    );
  }
}

async function getDecisionHistory(code: string) {
  if (!customerId?.value) return;
  historyDecisions.value = [];
  try {
    const historyDecisionsData = await BrandApprovalService.getApiV1Decision1(
      customerId?.value,
      code
    );
    historyDecisions.value = historyDecisionsData?.decisions || [];
  } catch (error) {
    historyDecisions.value = [];
  }
}

function getFilterOptions(value: string): SelectOption[] {
  if (value === "countryLabel") {
    return countryOptions.value;
  }
  if (value === "currency") {
    return currencyOptions.value;
  }
  if (value === "state") {
    return stateOptions.value;
  }
  if (value === "decision") {
    return decisionOptions.value;
  }
  if (value === "decisionType") {
    return decisionTypeOptions.value;
  }
  if (value === "tier") {
    return tierOptions.value;
  }
  return [];
}

function handleShowFilter() {
  showFilter.value = !showFilter.value;
}
function handleSorting() {
  if (sorting.value === "asc") {
    sorting.value = "desc";
  } else {
    sorting.value = "asc";
  }
  filterProductCatalogue();
}

function handleShowOverrideModal(value: ValidDecisions) {
  decision.value = value;
  showOverrideModal.value = !showOverrideModal.value;
}

function navigateToProductDetails(productCode: string) {
  // change parent window href as this view is meant to be rendered in an iframe
  window.parent.location.href = `${
    import.meta.env.VITE_SCION_BASE_URL
  }/admin/products/${productCode}`;
}

const updateItemsPerPage = (event: any) => {
  itemsPerPage.value = event.target.value;
};
const handleNextPage = () => {
  if (currentPage.value !== lastPage.value) {
    currentPage.value = currentPage.value + 1;
  }
};
const handlePreviousPageClick = () => {
  if (currentPage.value !== 1) {
    currentPage.value = Math.max(0, currentPage.value - 1);
  }
};

const canBeReset = computed(() => {
  return selectedProducts.value.every(
    (product) => product.tier !== "Tier 1" && product.tier !== "Tier 2"
  );
});

const isNotRestricted = computed(() => {
  return selectedProducts.value.every(
    (product) => product.decision !== DecisionStatusEnum.RESTRICTED
  );
});

async function refreshDataTable() {
  tableData.value = (tableData.value || []).map((product) => {
    const selectedProduct = selectedProducts.value.find(
      (row) => row.code === product.code
    );
    if (selectedProduct && !product.disabled) {
      product.disabled = true;
      product.selected = false;
      product.decision = STATUS_PROCESSING;
      productCatalogueData.forEach((prodductCatelogue) => {
        if (prodductCatelogue.code === product.code) {
          prodductCatelogue.decision = STATUS_PROCESSING;
          prodductCatelogue.disabled = true;
        }
      });
    }
    return product;
  });
  selectedProducts.value = [];
  isAllSameStatus.value = false;
  showOverrideModal.value = !showOverrideModal.value;
}
</script>

<template>
  <div v-if="loadingData" class="m-8">
    <LoadingSpinner />
  </div>
  <div v-else>
    <div v-if="isAllSameStatus" class="flex items-center">
      <div
        class="mb-6 mr-4 mt-4 flex cursor-pointer text-sm font-bold"
        v-if="
          selectedProducts.length > 0 &&
          selectedProducts[0]?.decision !== DecisionStatusEnum.DENIED &&
          isNotRestricted
        "
        @click="handleShowOverrideModal(DecisionStatusEnum.DENIED)"
      >
        <img src="/static/img/decline-icon.svg" class="mr-2" />
        Decline
      </div>
      <div
        class="mb-6 mt-4 flex cursor-pointer text-sm font-bold"
        v-if="
          selectedProducts.length > 0 &&
          selectedProducts[0]?.decision !== DecisionStatusEnum.APPROVED
        "
        @click="handleShowOverrideModal(DecisionStatusEnum.APPROVED)"
      >
        <img src="/static/img/approve-icon.svg" class="mr-2" />
        Approve
      </div>
      <div
        class="mt-4 mb-6 flex cursor-pointer items-center text-sm font-bold"
        v-if="
          selectedProducts.length > 0 &&
          selectedProducts[0]?.decision !==
            DecisionStatusEnum.REQUIRES_CUSTOMER_ACTION &&
          canBeReset &&
          isNotRestricted
        "
        @click="
          handleShowOverrideModal(DecisionStatusEnum.REQUIRES_CUSTOMER_ACTION)
        "
      >
        <font-awesome-icon
          class="custom-blue ml-2 mr-2"
          :icon="['far', 'rotate']"
        />
        Reset approval status
      </div>
    </div>
    <Table
      :tableData="currentPageData"
      :config="config"
      :showFilter="showFilter"
      :sorting="sorting"
      @handleSorting="handleSorting"
      class="rounded-lg"
    >
      <template #header-decisionType>
        <div class="flex w-full justify-between">
          <p>Decision</p>
          <p
            class="flex cursor-pointer text-hyperlink"
            data-testid="catalogue-filter"
            @click="handleShowFilter"
          >
            <img src="/static/img/filter.svg" class="mr-2" />
            Filters
          </p>
        </div>
      </template>
      <template #header-decision>
        <div class="flex w-full">
          <TwoPopover trigger="click" placement="top">
            <template #reference>
              <div class="flex">
                <p>Status</p>
                <img src="/static/img/infor.svg" class="ml-2 cursor-pointer" />
              </div>
            </template>
            <template v-slot="{ close }">
              <div
                class="max-w-md rounded border border-grey-200 px-4 py-6 shadow-lg"
              >
                <p class="mb-2 text-xl">Status Types</p>
                <p class="mb-2" v-for="status in tooltipStatus">
                  <span class="text-grey-600">{{ status.type }}</span>
                  <span class="font-normal">{{ status.description }}</span>
                </p>
              </div>
            </template>
          </TwoPopover>
        </div>
      </template>
      <template #body-product="{ row }">
        <div class="flex cursor-pointer items-center">
          <TwoCheckbox
            v-if="
              !isAllSameStatus ||
              (isAllSameStatus &&
                selectedProducts[0]?.decision === row.decision)
            "
            :class="`mr-4 h-4 w-4 ${row.disabled ? 'invisible' : ''}`"
            :modelValue="row.selected"
            @change="handleSelectedProducts(row)"
          />
          <div
            v-else
            class="mr-4 h-5 w-5 rounded-sm border-2 border-neutral-300 bg-neutral-300"
          ></div>
          <ProductFormatter
            @click="navigateToProductDetails(row.code)"
            :imageUrl="row.image_url"
            :code="row.code"
            :name="row.name"
          />
        </div>
      </template>
      <template #body-decision="{ row }">
        <StatusFormatter :text="row.decision" />
      </template>
      <template #body-decisionType="{ row }">
        <DecisionFormatter
          :decision-date="row.decision_date"
          :decision-type="row.decision_type"
          :note="row.override_reason"
          @get-decision-history="getDecisionHistory(row.code)"
          :history-decisions="historyDecisions"
        />
      </template>
      <template
        v-for="col in config"
        :key="`template-${col.value}`"
        #[`filter-${col.value}`]
      >
        <TwoSearchField
          v-if="col.searchable"
          data-testid="catalogue-search"
          @input="filterProductCatalogue"
          v-model="currentFilterState.searchTerm"
          label="Search products"
          placeholder="Product name"
        />
        <TwoSelectField
          v-else-if="col.filter"
          :label="col.text"
          placeholder="All"
          :options="getFilterOptions(col.value)"
          @change="filterProductCatalogue"
          v-model="currentFilterState[col.value]"
        />
      </template>
    </Table>
  </div>
  <OverrideDecisionModal
    v-if="showOverrideModal"
    :customerId="customerId"
    :decision="decision"
    :isRetrictedProducts="
      selectedProducts[0]?.decision === DecisionStatusEnum.RESTRICTED
    "
    :selected-products="selectedProducts"
    @refresh-data-table="refreshDataTable"
    @handle-show-override-modal="handleShowOverrideModal"
  />
  <div v-if="!loadingData" class="pagination">
    <div class="pagination__controls">
      <label>
        Show
        <select v-model="itemsPerPage" @change="updateItemsPerPage">
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
        entries
      </label>

      <div class="pagination__info">
        <template v-if="tableDataLength === 0"> Showing 0 entries </template>
        <template v-else>
          Showing
          {{ (currentPage - 1) * itemsPerPage + 1 }}
          to
          {{ Math.min(currentPage * itemsPerPage, tableDataLength) }}
          of
          {{ tableDataLength }} entries
        </template>
      </div>
    </div>

    <div class="pagination__buttons">
      <button
        :class="[
          'pagination__button',
          { 'pagination__button--disabled': currentPage === 1 },
        ]"
        @click="handlePreviousPageClick"
      >
        Previous
      </button>

      <span class="pagination__page-numbers">
        <button
          v-for="page in paginationArray"
          :key="page"
          :class="[
            'pagination__button',
            { 'pagination__button--current': currentPage === page },
          ]"
          @click="currentPage = typeof page === 'number' ? page : currentPage"
        >
          {{ page }}
        </button>
      </span>

      <button
        :class="[
          'pagination__button',
          { 'pagination__button--disabled': currentPage === lastPage },
        ]"
        @click="handleNextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>
