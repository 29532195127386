<!-- JSONForms custom renderer for multi select checkbox control -->
<script setup lang="ts">
import { rendererProps, useJsonFormsOneOfControl } from "@jsonforms/vue";
import { ControlElement } from "@jsonforms/core";
import { TwoCheckbox } from "@wegift/two-components";
import { ComputedRef } from "vue";

const props = defineProps({
  ...rendererProps<ControlElement>(),
});

// useJsonFormsOneOfControl's "control" property is actually a ComputedRef hence overriding it
const { control, handleChange } = useJsonFormsOneOfControl(
  props
) as unknown as Omit<ReturnType<typeof useJsonFormsOneOfControl>, "control"> & {
  control: ComputedRef<ReturnType<typeof useJsonFormsOneOfControl>["control"]>;
};

const onChange = (value: string[]) => {
  handleChange(control.value.path, [...value]);
};
</script>
<template>
  <TwoCheckbox
    v-for="op in control.schema.oneOf"
    class="mb-2"
    :model-value="Array.isArray(control.data) ? control.data : []"
    @change="onChange"
    :value="op.const"
    >{{ op.title }}</TwoCheckbox
  >
</template>
