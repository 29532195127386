<script setup lang="ts">
import { BrandApprovalApplicationResponse } from "@/api/brand-approval-application-service";
import BrandApprovalForm from "@/components/BrandApprovalForm.vue";
import { sendExitEvent } from "@/parent-interface";
import { TwoDialog } from "@wegift/two-components";
import { Ref, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { STEP_FORM_00 } from "../../constants";
const router = window.globalRouter || useRouter();
type BrandApprovalFormInstance = {
  onSubmit: () => void;
};
defineProps<{
  customerId: string;
  customerName: string;
  totalSteps: number;
  formData: BrandApprovalApplicationResponse | null;
  isConnect: boolean;
}>();
const showModal = ref(false);
let brandApprovalFormRef: Ref<BrandApprovalFormInstance | null> = ref(null);
const continueNextSteps: Ref<boolean> = ref(false);

const emit = defineEmits<{
  (e: "handleNextStep", currentStep: string): void;
  (e: "updateAnswersForm", data: any): void;
}>();

onMounted(() => {
  setTimeout(() => {
    const websiteUrlLabel = Array.from(document.querySelectorAll("label")).find(
      (el) => (el.textContent?.trim() ?? "") === "Website Url"
    );

    if (websiteUrlLabel && websiteUrlLabel.parentElement) {
      const inputElement = websiteUrlLabel.parentElement.querySelector("input");
      if (inputElement) {
        inputElement.placeholder = "https://";
      }
    }
  }, 500);
});

const handleNextStep = (data: any) => {
  if (continueNextSteps.value) {
    emit("handleNextStep", STEP_FORM_00);
    emit("updateAnswersForm", data);
  } else {
    sendExitEvent();
    router.push({ name: "home" });
  }
};

const handleYes = () => {
  continueNextSteps.value = true;
  brandApprovalFormRef.value?.onSubmit();
  showModal.value = false;
};

const handleNo = () => {
  continueNextSteps.value = false;
  brandApprovalFormRef.value?.onSubmit();
  showModal.value = false;
};
const handleClose = () => {
  showModal.value = false;
};

const openModal = () => {
  showModal.value = true;
};
</script>

<template>
  <TwoDialog
    data-testid="confirmation-modal"
    :show="showModal"
    title="Apply for more merchants?"
    description="Thank you for completing the approval form. </br>  </br>
Some merchants such as Amazon, Starbucks and Apple need a little more information about your business
before you can order from them.<br />  </br>
You will need to complete a short form which should not take longer than 5 minutes."
    btn-confirm-text="Continue"
    btn-cancel-text="Provide information later"
    @handleYes="handleYes"
    @handleNo="handleNo"
    @handleClose="handleClose"
  />

  <Suspense>
    <BrandApprovalForm
      ref="brandApprovalFormRef"
      :customer-id="customerId"
      :customer-name="customerName"
      @submit="handleNextStep"
      @openModal="openModal"
    />
  </Suspense>
</template>
