import { computed, ref } from "vue";
import { init, ValidationMode } from "@jsonforms/core";
import { JsonFormsChangeEvent } from "@jsonforms/vue";

export default function useForm(initialData = {}) {
  const data = ref<JsonFormsChangeEvent["data"]>(initialData);
  const errors = ref<JsonFormsChangeEvent["errors"]>();
  const validationMode = ref<ValidationMode>("ValidateAndHide");

  // for showing form level error in case any field is invalid
  const showSubmitError = computed(
    () => validationMode.value === "ValidateAndShow" && errors.value?.length
  );
  const change = (e: JsonFormsChangeEvent) => {
    data.value = e.data;
    errors.value = e.errors;
  };
  /**
   * Validates form and returns status. Also updates form to show error messages on fields
   */
  const validate = () => {
    if (errors.value?.length) {
      // for showing form field errors
      validationMode.value = "ValidateAndShow";
      return false;
    }
    return true;
  };

  const setData = (newData: JsonFormsChangeEvent["data"]) => {
    data.value = newData;
  };
  return {
    data,
    errors,
    validationMode,
    change,
    validate,
    showSubmitError,
    setData,
  };
}
