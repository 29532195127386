<script setup lang="ts">
import BackButton from "@/components/BackButton.vue";
import ManualProductOverride from "@/components/ManualProductOverride.vue";
import productCatalogueStore from "@/sub-stores/product-catalogue";
import { computed } from "@vue/reactivity";
import { useRouter } from "vue-router";

defineProps<{
  productCode: string;
  decision: "approved" | "denied";
}>();
const router = useRouter();
const customerId = computed(() => productCatalogueStore.state.customerId);
</script>

<template>
  <main class="bg-white p-8" v-if="customerId">
    <BackButton @click="router.push({ name: 'product-catalogue' })"
      >Back</BackButton
    >
    <ManualProductOverride
      class="mt-8 max-w-xl"
      @override="router.push({ name: 'product-catalogue' })"
      @cancel="router.push({ name: 'product-catalogue' })"
      :decision="decision"
      :customer-id="customerId"
      :product-code="productCode"
    />
  </main>
</template>
