import { createRouter, createWebHistory } from "vue-router";
import type { RouteRecordRaw } from "vue-router";
import ProductCatalogueView from "@/views/ProductCatalogueView.vue";
import DebugView from "@/views/DebugView.vue";
import HelloView from "@/views/HelloView.vue";
import ManualOverrideView from "./views/ProductOverrideView.vue";
import ApprovalFormView from "./views/ApprovalFormView.vue";
import T2Form from "@/views/forms/T2Form.vue";
import T3Form from "@/views/forms/T3Form.vue";
import FormBase from "@/views/forms/FormBase.vue";
import SelectCountries from "@/views/forms/SelectCountries.vue";
import SelectProducts from "@/views/forms/SelectProducts.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "default",
    component: HelloView,
  },
  {
    path: "/debug",
    name: "debug",
    component: DebugView,
  },
  {
    path: "/approval-form",
    name: "approval-form",
    component: ApprovalFormView,
  },
  {
    path: "/product-catalogue",
    name: "product-catalogue",
    component: ProductCatalogueView,
  },
  {
    path: "/product-override",
    name: "product-override",
    component: ManualOverrideView,
    props: true,
  },
  {
    path: "/manual-approval-form",
    name: "manual-approval-form",
    redirect: "/forms",
  },
  {
    path: "/forms",
    name: "forms",
    component: FormBase,
    children: [
      {
        path: "t2",
        name: "t2-form",
        component: T2Form,
      },
      {
        path: "select-countries",
        name: "select-countries",
        component: SelectCountries,
      },
      {
        path: "select-products",
        name: "select-products",
        component: SelectProducts,
      },
      {
        path: "t3",
        name: "t3-form",
        component: T3Form,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
