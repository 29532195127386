<script setup lang="ts">
import { ProductCatalogueTableRow } from "@/types";
import { defineProps } from "vue";
import { TypeConfig } from "./TableConfig";

const emit = defineEmits(["handleSorting"]);

defineProps<{
  config: TypeConfig[];
  tableData: ProductCatalogueTableRow[] | undefined;
  showFilter: boolean;
  sorting: "asc" | "desc";
}>();

function handleSorting() {
  emit("handleSorting");
}
</script>

<template>
  <div class="border border-neutral-300">
    <table class="w-full overflow-hidden">
      <thead>
        <tr class="border-b border-neutral-300 bg-grey-50 text-left">
          <th
            v-for="col in config"
            :key="`header-${col.value}`"
            :class="[col.style && col.style, `py-3.5 text-sm`]"
          >
            <div class="flex w-full">
              <slot :name="`header-${col.value}`"
                ><p>{{ col.text }}</p></slot
              >
              <img
                v-if="col.sortable"
                @click="handleSorting"
                :class="[
                  `ml-2 cursor-pointer rounded bg-grey-100 ${
                    sorting === 'asc' ? 'rotate-0' : 'rotate-180'
                  }`,
                ]"
                src="/static/img/sort-icon.svg"
                alt="sort"
                width="20"
                height="20"
              />
            </div>
          </th>
        </tr>
        <tr class="bg-white text-left" v-if="showFilter">
          <th
            v-for="(col, idx) in config"
            :key="`filter-${col.value}`"
            :class="`font-normal ${idx === 0 ? 'p-4' : 'pr-4'}`"
          >
            <slot :name="`filter-${col.value}`"></slot>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white" v-if="tableData && tableData.length">
        <tr
          v-for="row in tableData"
          :key="row.code"
          :class="`border-t border-neutral-300 hover:bg-grey-50 ${
            row.disabled ? 'pointer-events-none opacity-40' : ''
          }`"
        >
          <td
            v-for="(item, idx) in config"
            :class="`${idx === 0 ? 'py-4 pl-4' : 'py-4'}`"
          >
            <slot :name="`body-${item.value}`" :row="row">{{
              row[item.value]
            }}</slot>
          </td>
        </tr>
      </tbody>
      <tbody class="bg-white" v-else>
        <tr>
          <td class="py-4 text-center" :colspan="config.length">
            No products found
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
