import { reactive, readonly } from "vue";

interface ProductCatalogueState {
  customerId: string | null;
  customerName: string | null;
}

const state = reactive<ProductCatalogueState>({
  customerId: null,
  customerName: null,
});

const productCatalogueStore = {
  state: readonly(state),
  setCustomer(id: string, name: string) {
    state.customerId = id;
    state.customerName = name;
  },
  getCustomerId() {
    return state.customerId;
  },
};

export default productCatalogueStore;
